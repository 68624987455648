import React from 'react';
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import { SpinnerCircular } from 'spinners-react'

import * as ROUTES from '../constants/routes'
import Logo from '../components/Navigation/Logo'
import { Link } from 'gatsby'
import Layout from '../components/layout';
import HeadData from "../data/HeadData"

const FAEmbedRequestAccessForm = loadable(() =>
  pMinDelay(import('../components/Common/FAEmbedRequestAccessForm'), 2000),{
    fallback:
      <div className="flex flex-col justify-center items-center mt-6">
        <SpinnerCircular color="#bb0000" secondaryColor="#FEF2F2" size="70" />
        <h2 className="text-center text-sm italic mt-3">Loading Form</h2>
      </div>
  }
)

const RequestAccessPage = () => (
  <div className="flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-2xl w-full">
      <div className='flex flex-col items-center justify-center'>
        <Link to={ROUTES.LANDING}>
          <Logo className='w-40 mx-auto' />
        </Link>

        <h2 className="text-lg md:text-xl text-gray-900 font-bold">
          Marketing Portal Access Request Form
        </h2>
        <p className="text-sm md:text-base text-center text-gray-800 mt-6">Please use this form to send a formal request to be added to our Marketing Portal. After the request has been received and approval has been made, you will be contacted by a SRA employee with login details and information regarding next steps. Thank you.</p>
      </div>

      <div className="max-w-2xl flex flex-col items-center justify-center mt-6 pt-2 pb-4">
        <FAEmbedRequestAccessForm formId="4930001" xlWidth="300" lgWidth="300" mdWidth="300" smWidth="330" xsWidth="280" height="420" />
      </div>

      <p className="mt-4 text-center text-sm leading-5 text-gray-600">
        <Link to='/' className='underline text-gray-500 hover:text-gray-600'>Back to Sign In Page</Link>
      </p>
    </div>
  </div>
);

const RequestAccess = () => (
  <Layout>
    <HeadData title='Request Access' />
    <RequestAccessPage />
  </Layout>
)

export default RequestAccess
